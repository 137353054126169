
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}

html {
  scroll-behavior: smooth;
}

.App {
    background-image: url("https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629988935/OpenGridLandingPage/OpenGrid_Website_Background_djc2db.jpg");
    background-attachment: fixed;
    background-size: cover;
    top: 0em;
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    z-index: 0;
    transform: scale(1);
    filter: blur(0px); 
    z-index: 100; 
    opacity: 1; 
    transition: 1s;
    height: 100vh;
    width: 100vw;
  }

canvas {
    display: block;
}

.containerStyle {
    background-color: black; 
    min-width: 100vw; 
    height: 100vh; 
    min-height: 100vh; 
    overflow-y: hidden; 
    overflow-x: hidden; 
    z-index: 0;
    transition: 1s;
    filter: blur(10px);
    opacity: 0;
}

.opengrid {
    z-index: 100;
    filter: blur(0px);
    opacity: 1;
}

#opengrid-canvas {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background: black;
}
  
  .navWrapper {
    height: 6.5rem;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
  }
  
  @media screen and (max-width: 1080px) {
    .navWrapper {
      height: 9em;
    }
  }
  
  .container {
    top: 0em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -101;
    overflow-y: inherit;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 6.5em;
  }
  

  
  #features {
    width: 100vw;
    padding-top: 0vh;
  }
  
  #features::before {    
      z-index:-1;
      content: "";
      background-image: url('https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629991225/OpenGridLandingPage/OG_CompoundGrid_otytgy.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 110vh;
      left: 0px;
      height: calc(100vh - 4.5rem);
      width: 100vw;
      opacity: 0.1;
  }

  .beam-image-split {
      position: relative;
  }

.hoverText {
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  position: absolute;
  align-items: center;
  margin: auto;
  top: 45%;
  color: #FFC85A;
  left: 15vw;
  max-width: 275px;
  z-index: 100;
}

  .navLogo {
    background-image: url('https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629990704/OpenGridLandingPage/OG_Logo_Black_Transparency_2x_wuj25t.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 5rem;
    width: 5rem;
    margin-left: 24px;
    padding-top: 40px;
  }

  .passwordForm {
    text-align: 'center';
    align-items: 'center';
    background-color: 'white';
    padding-top: 150;
    height: '100vh'; 
    width: '100%'; 
    max-width: 1200;
  }

  header.modal-card-head,
  footer.modal-card-foot {
    border: 1px solid #f1f2f2;
    background: white;
  }
  
  .form-control {
    margin-bottom: 1rem;
  }
  
  .form-control input,
  .form-control label {
    display: block;
  }
  
  .form-control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-control input,
  .form-control select {
    font: inherit;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 20rem;
    max-width: 100%;
  }
  
  .form-control input:focus {
    outline: none;
    border-color: lightblue;
    background-color: lightskyblue;
  }
  
  .control-group {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
  }
  
  .control-group .form-control {
    min-width: 15rem;
    flex: 1;
  }
  
  .form-actions {
    text-align: center;
  }
  
  .form-actions button {
    margin-left: 1rem;
  }
  
  .invalid input {
    border: 1px solid #b40e0e;
    background-color: #fddddd;
  }
  
  .invalid input:focus {
    border-color: #ff8800;
    background-color: #fbe8d2;
  }
  
  .error-text {
    color: #b40e0e;
  } 

#footer {
  margin: 0;
  text-align: center;
}

#footer > p {
  color: white;
  margin: 0;
  padding-bottom: 2em;
  font-size: 1.2em;
  margin-bottom: -100vh;
}
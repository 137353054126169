  .heroWrapper {
    padding: 12vh 0;
    /* padding-top: 15vh; */
    justify-content: center;
    display: inline-flex;
    flex-wrap: wrap;
  }
  
  .heroWrapper::before {    
      z-index: -10;
      content: "";
      background-image: url('https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629991123/OpenGridLandingPage/OG_Wireframe_oldpsj.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 2vh;
      left: 2em;
      height: calc(110vh - 4.5rem);
      width: 90%;
      mix-blend-mode: soft-light;
      opacity: 0.2;
  }
  
  .hero1 {
    color: #2A3137;
    opacity: 1;
    line-height: 10vw;
    z-index: 100;
    margin-top: 0%;
  }
  
  .heroTitle-Bold {
    text-align: center;
    font-weight: 900;
    letter-spacing: 0;
    font-size: 11vw;
    margin: 0;
  
  }
  
  .heroTitle-Normal {
    text-align: center;
    letter-spacing: 1vw;
    font-size: 10.5vw;
    font-weight: 400;
    margin: 0;
    padding-inline-start: 2vw;
  }
  

  .heroChild {
    display: inline-flex;
    width: 50%;
    min-width: 350px;
    justify-content: center;
    vertical-align: auto;
  }
  .heroCategoriesWrapper {
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.1rem;
    word-spacing: 0.5vw;
    color: #2A3137;
    opacity: 1;
    font-weight: 600;
  }
  
  .heroCategories {
    font-size: 1.1vw;
  }
  
  .heroImg {
    background-image: url('https://res.cloudinary.com/dq5lw8oy1/image/upload/v1630001799/OpenGridLandingPage/Landing_2x_b09yo8.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: auto;
    height: 65vh;
    /* margin: 2vw; */
    margin-top: 0vh;
  }
  
  .responsive-image {
    position: relative;
    max-width: 100%;
  }
  
  .responsive-image__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .hero2 {
    line-height: 0.5rem;
    letter-spacing: 0px;
    text-align: center;
  }
  
  .hero2-Title {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 2vw;
    font-family: 'Raleway Extrabold';
  }
  
  .hero2-Description {
    font-weight: normal;
    font-size: 1.25em;
    word-spacing: 0.11rem;
    letter-spacing: 0;
    max-width: 60%;
    margin: auto;
    line-height: 1.5rem;
    font-family: 'Raleway';
  }
  .hero2-Subtitle {
    font-size: 1em;
    margin-bottom: 10%;
  }

  @media screen and (min-width:600px) and (max-width:796px) {
    .hero1 {
      line-height: 20vw;
      margin-top: 5%;
    }

    .heroTitle-Bold {
      font-size: 20vw;
    }
    .heroTitle-Normal {
      font-size: 20vw;
      padding-inline-start: 0.5vw;
    }

    .hero2 {
      line-height: 0.5rem;
      letter-spacing: 0px;
      text-align: center;
    }  
    .hero2-Title {
      margin-top: 0%;
      font-size: 4vw;
      line-height: 4vw;
    }
    .hero2-Description {
      font-size: 2vw;
      max-width: 80%;
    } 
    .hero2-Subtitle {
      font-size: 1vw;
      margin-bottom: 10%;
    }

  }

  @media screen and (max-width:600px) {
    .hero1 {
      line-height: 25vw;
    }

    .heroTitle-Bold {
      font-size: 28vw;
    }
    .heroTitle-Normal {
      font-size: 28vw;
      padding-inline-start: 0.5vw;
    }
    .hero2-Title {
      margin-top: 30%;
      font-size: 6vw;
    }
    .hero2-Description {
      font-size: 4vw;
      max-width: 80%;
    } 
    .hero2-Subtitle {
      font-size: 2vw;
      margin-bottom: 10%;
    }

  }

  @media screen and (orientation: portrait) {
    .heroChild {
      width: 80vw;
    }  
  }